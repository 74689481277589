
export default {
    data() {
        return {
            startChartLabel: '',
            endChartLabel: '',
            desserts: [],
            prices: {
                week: [],
                month: [],
                threeMonth: [],
                all: []
            },
            tabButtons: [
                { id: 0, name: "7 روز" },
                { id: 1, name: "30 روز" },
                { id: 2, name: "3 ماه" },
                { id: 3, name: "همه" },
            ],
            selectedDay: 0,
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                    intersect: false,
                    mode: "index",
                },
                plugins: {
                    legend: {
                        display: false,
                        position: "bottom",
                    },
                    filler: {
                        propagate: true,
                    },
                    tooltip: {
                        rtl: true,
                        titleFont: {
                            family: "iransans",
                        },
                        bodyFont: {
                            family: "iransans",
                        },
                    },
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                            color: "rgba(27, 152, 245, .05)",
                            drawBorder: false,
                            drawOnChartArea: true,
                            drawTicks: true,
                        },
                        ticks: {
                            display: false
                        },
                    },
                }
            },
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: "نرخ تتر",
                        fill: true,
                        borderWidth: 4,
                        borderColor: "#27dbaf",
                        backgroundColor: "rgb(0, 158, 137, .025)",
                        pointBorderWidth: 0,
                        pointBackgroundColor: "transparent",
                        data: [],
                        tension: 0.4,
                    }
                ],
            },
            price: {
                usdt: 0,
                lowPrice: 0,
                hightPrice: 0,
                percentageDifference: 0,
            },
        }
    },

    watch: {
        selectedDay(newVal) {
            console.log(newVal)
            this.chartData.labels = []
            this.chartData.datasets[0].data = []
            if (newVal === 0) {
                this.startChartLabel = new Date(this.prices.week[0].createdAt).toLocaleDateString('fa-IR')
                this.endChartLabel = new Date(this.prices.week[6].createdAt).toLocaleDateString('fa-IR')
                this.prices.week.forEach(element => {
                    this.chartData.labels.push(new Date(element.createdAt).toLocaleDateString('fa-IR'))
                    this.chartData.datasets[0].data.push(element.price)
                });
            } else if (newVal === 1) {
                this.startChartLabel = new Date(this.prices.month[0].createdAt).toLocaleDateString('fa-IR')
                this.endChartLabel = new Date(this.prices.month[29].createdAt).toLocaleDateString('fa-IR')
                this.prices.month.forEach(element => {
                    this.chartData.labels.push(new Date(element.createdAt).toLocaleDateString('fa-IR'))
                    this.chartData.datasets[0].data.push(element.price)
                });
            } else if (newVal === 2) {
                this.startChartLabel = new Date(this.prices.threeMonth[0].createdAt).toLocaleDateString('fa-IR')
                this.endChartLabel = new Date(this.prices.threeMonth[89].createdAt).toLocaleDateString('fa-IR')
                this.prices.threeMonth.forEach(element => {
                    this.chartData.labels.push(new Date(element.createdAt).toLocaleDateString('fa-IR'))
                    this.chartData.datasets[0].data.push(element.price)
                });
            } else if (newVal === 3) {
                const len = this.prices.all.length
                this.startChartLabel = new Date(this.prices.all[0].createdAt).toLocaleDateString('fa-IR')
                this.endChartLabel = new Date(this.prices.all[len - 1].createdAt).toLocaleDateString('fa-IR')
                this.prices.all.forEach(element => {
                    this.chartData.labels.push(new Date(element.createdAt).toLocaleDateString('fa-IR'))
                    this.chartData.datasets[0].data.push(element.price)
                });
            }
        }
    },

    methods: {
        getDifference(index) {
            if (index < this.desserts.length - 1)
                return +this.desserts[index].price - +this.desserts[index + 1].price;
            return 0
        }
    },

    async fetch() {
        const response = await this.$axios.$get('/v1/price/price-chart')
        this.desserts = response.todayPrices.slice(0, 7);
        await this.$store.dispatch("usdtConfig/set", response.price);
        this.price = response.price;
        this.prices.week = response.prices.slice(0, 7).reverse();
        this.prices.month = response.prices.slice(0, 30).reverse();
        this.prices.threeMonth = response.prices.slice(0, 90).reverse();
        this.prices.all = response.prices.reverse();
        this.startChartLabel = new Date(this.prices.week[0].createdAt).toLocaleDateString('fa-IR')
        this.endChartLabel = new Date(this.prices.week[6].createdAt).toLocaleDateString('fa-IR')
        this.prices.week.forEach(element => {
            this.chartData.labels.push(new Date(element.createdAt).toLocaleDateString('fa-IR'))
            this.chartData.datasets[0].data.push(element.price)
        });
    }
}
