import { render, staticRenderFns } from "./tether-chart.vue?vue&type=template&id=3ea817ac&scoped=true"
import script from "./tether-chart.vue?vue&type=script&lang=js"
export * from "./tether-chart.vue?vue&type=script&lang=js"
import style0 from "./tether-chart.vue?vue&type=style&index=0&id=3ea817ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ea817ac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PublicTabBtnC: require('/app/components/public/TabBtnC.vue').default,CoreSelectDay: require('/app/components/core/select/Day.vue').default,PublicTetherFormWide: require('/app/components/public/tether/FormWide.vue').default})
